import React from 'react';
import { FaCheck } from 'react-icons/fa';
import PageHeader from '../PageHeader';
import OfficeInfo from '../Contact/OfficeInfo';
import styles from './contactThankYou.module.scss';

const ContactThankYou = () => {
  const success = <FaCheck size={44} color="#45ab7f" />;
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108930/headerBackgroundContact_lxkmce.jpg';

  return (
    <main>
      <PageHeader pageName="Contact Us" headerImage={background} />
      <div className={styles.wrapper}>
        <div className={styles.messageContainer}>
          {success}
          <span>Thank you for your message. We will contact you shortly.</span>
        </div>
      </div>
      <OfficeInfo />
    </main>
  );
};

export default ContactThankYou;
